var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"filters"}},[(_vm.filterGroups.length)?[_vm._l((_vm.filterGroups),function(group,groupIdx){return [_c('div',{key:group.id,staticClass:"filter-group"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"label q-mr-md"},[_vm._v("Column")]),_c('div',{staticClass:"label q-mr-md"},[_vm._v("Condition")]),_c('div',{staticClass:"label q-mr-sm"},[_vm._v("Value")]),_c('BaseActionButton',{attrs:{"is-flat":"","color":"secondary","icon":"eva-plus","no-border":""},on:{"click":function($event){return _vm.addFilter(groupIdx)}}})],1),_c('BaseSeparator',{staticClass:"q-mb-md",attrs:{"has-inset":""}}),_vm._l((group.filters),function(filter,filterIdx){return _c('ValidationObserver',{key:filter.id,ref:"form",refInFor:true,staticClass:"filter"},[_c('ValidationProvider',{key:filter.id,staticClass:"col q-mr-md",attrs:{"name":"criteria","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SelectField',{attrs:{"is-mandatory":"","is-searchable":true,"options":_vm._columns,"error":errors[0]},on:{"input":function($event){return _vm.applyDataType(groupIdx, filterIdx, filter.criteria)}},model:{value:(filter.criteria),callback:function ($$v) {_vm.$set(filter, "criteria", $$v)},expression:"filter.criteria"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col q-mr-md",attrs:{"name":"condition","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SelectField',{attrs:{"is-mandatory":"","is-searchable":true,"options":_vm.conditions(filter.criteria),"error":errors[0]},model:{value:(filter.condition),callback:function ($$v) {_vm.$set(filter, "condition", $$v)},expression:"filter.condition"}})]}}],null,true)}),(
              filter.dataType === 'SHORT_TEXT' ||
              filter.dataType === 'BARCODE'
            )?_c('ValidationProvider',{staticClass:"col q-mr-sm",attrs:{"name":"value","rules":{
              required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                filter.condition
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextField',{attrs:{"is-mandatory":"","is-disabled":['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition),"error":errors[0]},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}],null,true)}):(filter.dataType === 'NUMBER')?_c('ValidationProvider',{staticClass:"col q-mr-sm",attrs:{"name":"value","rules":{
              required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                filter.condition
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('NumberField',{attrs:{"is-mandatory":"","is-disabled":['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition),"error":errors[0]},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}],null,true)}):(filter.dataType === 'DATE')?_c('ValidationProvider',{staticClass:"col q-mr-sm",attrs:{"name":"value","rules":{
              required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                filter.condition
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateField',{attrs:{"is-mandatory":"","is-disabled":['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition),"error":errors[0]},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}],null,true)}):(filter.dataType === 'TIME')?_c('ValidationProvider',{staticClass:"col q-mr-sm",attrs:{"name":"value","rules":{
              required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                filter.condition
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TimeField',{attrs:{"is-mandatory":"","is-disabled":['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition),"error":errors[0]},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}],null,true)}):(filter.dataType === 'SINGLE_SELECT')?_c('ValidationProvider',{staticClass:"col q-mr-sm",attrs:{"name":"value","rules":{
              required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                filter.condition
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('AsyncSelectField',{attrs:{"is-mandatory":"","is-disabled":['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition),"error":errors[0],"api-path":_vm.apiPath,"column-name":filter.criteria},model:{value:(filter.arrayValue),callback:function ($$v) {_vm.$set(filter, "arrayValue", $$v)},expression:"filter.arrayValue"}})]}}],null,true)}):_c('ValidationProvider',{staticClass:"col q-mr-sm",attrs:{"name":"value","rules":{
              required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                filter.condition
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextField',{attrs:{"is-mandatory":"","is-disabled":['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition),"error":errors[0]},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}],null,true)}),_c('div',{staticClass:"remove-filter"},[_c('BaseActionButton',{attrs:{"is-flat":"","color":"red","no-border":"","icon":"eva-close-outline"},on:{"click":function($event){return _vm.removeFilter(groupIdx, filterIdx)}}})],1)],1)}),(false)?_c('div',{staticClass:"row"},[_c('Action',{staticClass:"col",attrs:{"icon":"eva-plus-outline","label":"Add Filter Group"},on:{"click":function($event){return _vm.addFilterGroup(groupIdx + 1)}}}),_c('BaseSeparator',{attrs:{"is-vertical":"","has-inset":""}}),_c('Action',{staticClass:"col",attrs:{"icon":"eva-trash-2-outline","label":"Delete Group"},on:{"click":function($event){return _vm.removeFilterGroup(groupIdx)}}})],1):_vm._e()],2),(groupIdx !== _vm.filterGroups.length - 1 && false)?_c('div',{key:groupIdx,staticClass:"row justify-center"},[_c('Toggle',{staticClass:"q-my-md",attrs:{"options":_vm.groupConditions},on:{"input":_vm.groupCondition},model:{value:(group.groupCondition),callback:function ($$v) {_vm.$set(group, "groupCondition", $$v)},expression:"group.groupCondition"}})],1):_vm._e()]})]:[_c('div',{staticClass:"add-filter",on:{"click":_vm.addFilterGroup}},[_c('BaseIcon',{attrs:{"name":"eva-plus","inherit-color":""}}),_c('div',{staticClass:"q-ml-sm"},[_vm._v("Add Columns")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }