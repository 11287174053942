<template>
  <div id="filters">
    <template v-if="filterGroups.length">
      <template v-for="(group, groupIdx) in filterGroups">
        <div :key="group.id" class="filter-group">
          <!-- header -->

          <div class="header">
            <div class="label q-mr-md">Column</div>
            <div class="label q-mr-md">Condition</div>
            <div class="label q-mr-sm">Value</div>

            <BaseActionButton
              is-flat
              color="secondary"
              icon="eva-plus"
              no-border
              @click="addFilter(groupIdx)"
            />
          </div>

          <!-- ... -->

          <BaseSeparator has-inset class="q-mb-md" />

          <!-- filters -->

          <ValidationObserver
            v-for="(filter, filterIdx) in group.filters"
            ref="form"
            :key="filter.id"
            class="filter"
          >
            <!-- criteria -->

            <ValidationProvider
              :key="filter.id"
              v-slot="{ errors }"
              name="criteria"
              :rules="{ required: true }"
              class="col q-mr-md"
            >
              <SelectField
                v-model="filter.criteria"
                is-mandatory
                :is-searchable="true"
                :options="_columns"
                :error="errors[0]"
                @input="applyDataType(groupIdx, filterIdx, filter.criteria)"
              />
            </ValidationProvider>

            <!-- ... -->

            <!-- condition -->

            <ValidationProvider
              v-slot="{ errors }"
              name="condition"
              :rules="{ required: true }"
              class="col q-mr-md"
            >
              <SelectField
                v-model="filter.condition"
                is-mandatory
                :is-searchable="true"
                :options="conditions(filter.criteria)"
                :error="errors[0]"
              />
            </ValidationProvider>

            <!-- ... -->

            <!-- value -->

            <ValidationProvider
              v-if="
                filter.dataType === 'SHORT_TEXT' ||
                filter.dataType === 'BARCODE'
              "
              v-slot="{ errors }"
              name="value"
              :rules="{
                required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                  filter.condition
                ),
              }"
              class="col q-mr-sm"
            >
              <TextField
                v-model="filter.value"
                is-mandatory
                :is-disabled="
                  ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                "
                :error="errors[0]"
              />
            </ValidationProvider>

            <ValidationProvider
              v-else-if="filter.dataType === 'NUMBER'"
              v-slot="{ errors }"
              name="value"
              :rules="{
                required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                  filter.condition
                ),
              }"
              class="col q-mr-sm"
            >
              <NumberField
                v-model="filter.value"
                is-mandatory
                :is-disabled="
                  ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                "
                :error="errors[0]"
              />
            </ValidationProvider>

            <ValidationProvider
              v-else-if="filter.dataType === 'DATE'"
              v-slot="{ errors }"
              name="value"
              :rules="{
                required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                  filter.condition
                ),
              }"
              class="col q-mr-sm"
            >
              <DateField
                v-model="filter.value"
                is-mandatory
                :is-disabled="
                  ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                "
                :error="errors[0]"
              />
            </ValidationProvider>

            <ValidationProvider
              v-else-if="filter.dataType === 'TIME'"
              v-slot="{ errors }"
              name="value"
              :rules="{
                required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                  filter.condition
                ),
              }"
              class="col q-mr-sm"
            >
              <TimeField
                v-model="filter.value"
                is-mandatory
                :is-disabled="
                  ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                "
                :error="errors[0]"
              />
            </ValidationProvider>

            <ValidationProvider
              v-else-if="filter.dataType === 'SINGLE_SELECT'"
              v-slot="{ errors }"
              name="value"
              :rules="{
                required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                  filter.condition
                ),
              }"
              class="col q-mr-sm"
            >
              <AsyncSelectField
                v-model="filter.arrayValue"
                is-mandatory
                :is-disabled="
                  ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                "
                :error="errors[0]"
                :api-path="apiPath"
                :column-name="filter.criteria"
              />
            </ValidationProvider>

            <ValidationProvider
              v-else
              v-slot="{ errors }"
              name="value"
              :rules="{
                required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                  filter.condition
                ),
              }"
              class="col q-mr-sm"
            >
              <TextField
                v-model="filter.value"
                is-mandatory
                :is-disabled="
                  ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                "
                :error="errors[0]"
              />
            </ValidationProvider>

            <!-- ... -->

            <!-- remove -->

            <div class="remove-filter">
              <BaseActionButton
                is-flat
                color="red"
                no-border
                icon="eva-close-outline"
                @click="removeFilter(groupIdx, filterIdx)"
              />
            </div>

            <!-- ... -->
          </ValidationObserver>

          <!-- ... -->

          <!-- <BaseSeparator has-inset /> -->

          <div v-if="false" class="row">
            <Action
              icon="eva-plus-outline"
              label="Add Filter Group"
              class="col"
              @click="addFilterGroup(groupIdx + 1)"
            />

            <BaseSeparator is-vertical has-inset />

            <Action
              icon="eva-trash-2-outline"
              label="Delete Group"
              class="col"
              @click="removeFilterGroup(groupIdx)"
            />
          </div>
        </div>

        <div
          v-if="groupIdx !== filterGroups.length - 1 && false"
          :key="groupIdx"
          class="row justify-center"
        >
          <Toggle
            v-model="group.groupCondition"
            :options="groupConditions"
            class="q-my-md"
            @input="groupCondition"
          />
        </div>
      </template>
    </template>

    <template v-else>
      <div class="add-filter" @click="addFilterGroup">
        <BaseIcon name="eva-plus" inherit-color />

        <div class="q-ml-sm">Add Columns</div>
      </div>
    </template>
  </div>
</template>
<script>
import { lowerCase, startCase } from "lodash-es";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import Action from "@/components/common/Action.vue";
import Toggle from "@/components/common/Toggle.vue";
import Conditions from "@/helpers/conditions.js";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
import { isEmpty } from "lodash-es";

export default {
  name: "Filters",

  components: {
    ValidationObserver,
    ValidationProvider,
    TextField,
    SelectField,
    Action,
    Toggle,
    NumberField,
    DateField,
    TimeField,
    AsyncSelectField,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    searchValue: {
      type: String,
      default: "",
    },

    filterBy: {
      type: Array,
      required: true,
    },

    editColumns: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: false,
      groupConditions: ["AND", "OR"],
      filterGroups: [],
      activeFilter: false,
      searchText: "",
    };
  },

  computed: {
    _columns() {
      var data = this.columns.reduce((ids, column) => {
        ids.push({
          id: column.id,
          label: column.label,
          value: column.value,
          dataType: column.dataType,
        });

        return ids;
      }, []);
      // console.log(data);
      return data;
      // return this.columns.map((column) => ({
      //   id: column.id,
      //   label: column.label,
      //   value: column.name,
      // }));
    },
  },

  watch: {
    filterGroups: {
      deep: true,
      handler() {
        this.$emit("update:filterBy", this.filterGroups);
      },
    },

    filterBy: {
      immediate: true,
      handler() {
        if (this.filterBy.length) {
          this.filterGroups = this.filterBy;
        }
        // this.$emit("update:filterBy", this.filterGroups);
      },
    },
  },

  created() {
    if (isEmpty(this.editColumns)) {
      this.addFilterGroup(0);
    }
  },

  methods: {
    conditions(columnName) {
      // console.log(columnName);

      if (!columnName) {
        return [];
      }

      // console.log(columnName);
      // console.log(this.columns);

      const dataType = this.columns.find(
        (column) => column.value === columnName
      );
      if (dataType) {
        return Conditions(dataType.dataType).map((condition) => ({
          id: this.$nano.id(),
          label: `${startCase(lowerCase(condition.split(" ")[0]))} ${
            condition.split(" ")[1] ? condition.split(" ")[1] : ""
          }`,
          value: condition.split(" ")[0],
        }));
      }
    },

    addFilterGroup(groupIdx = 0) {
      this.filterGroups.splice(groupIdx, 0, {
        id: this.$nano.id(),
        filters: [
          {
            id: this.$nano.id(),
            criteria: "",
            condition: "",
            value: "",
          },
        ],
        groupCondition: this.filterGroups.length ? "" : "",
      });
    },

    removeFilterGroup(groupIdx) {
      this.filterGroups.splice(groupIdx, 1);
    },

    addFilter(groupIdx) {
      console.log(groupIdx);
      this.filterGroups[groupIdx].filters.push({
        id: this.$nano.id(),
        criteria: "",
        condition: "",
        value: "",
      });
    },

    removeFilter(groupIdx, filterIdx) {
      this.filterGroups[groupIdx].filters.splice(filterIdx, 1);
    },

    async apply() {
      let invalidFields = 0;

      if (!this.$refs.form) {
        this.$emit("update", this.filterGroups);
        this.model = false;
        this.activeFilter = true;

        return;
      }

      for (const form of this.$refs.form) {
        const isValid = await form.validate();
        if (!isValid) {
          invalidFields++;
        }
      }

      if (invalidFields) {
        return;
      }
      this.filterGroups[0].groupCondition = "";
      if (this.filterGroups.length) {
        if (!this.filterGroups[0].filters.length) {
          this.filterGroups = [];
        }
        this.filterGroups.forEach((group) => {
          if (group.filters.length) {
            group.filters.forEach((row) => {
              if (row.dataType === "SINGLE_SELECT") {
                row.value = JSON.stringify(row.arrayValue);
              }
            });
          }
        });
      }
      this.$emit("update", this.filterGroups);
      this.model = false;
      this.activeFilter = true;
    },

    applyDataType(groupIdx, filterIdx, name) {
      // console.log(groupIdx, filterIdx, name);
      this.filterGroups[groupIdx].filters[filterIdx].value = "";
      this.filterGroups[groupIdx].filters[filterIdx].condition = "";
      this.filterGroups[groupIdx].filters[filterIdx].condition = "IS_EQUALS_TO";

      this.filterGroups[groupIdx].filters[filterIdx].dataType = "";
      // console.log(this.filterGroups);
      let type = this._columns.find((item) => item.label === name);
      if (type) {
        this.filterGroups[groupIdx].filters[filterIdx].dataType = type.dataType;
      }
    },

    clearFilter() {
      this.filterGroups = [];
      this.activeFilter = false;
      // this.model = false;
      this.searchText = "";
      this.$emit("updateSearchValue", "");
      this.$emit("update", this.filterGroups);
    },

    groupCondition(condition) {
      // console.log(condition);
      this.filterGroups[this.filterGroups.length - 1].groupCondition =
        condition;
    },
  },
};
</script>

<style lang="scss" scoped>
#filters {
  //padding: 16px;

  .description {
    margin-bottom: 24px;
  }

  .add-filter {
    display: flex;
    align-items: center;
    color: var(--secondary);
    cursor: pointer;
  }

  .filter-group {
    border: 1px solid var(--divider-color);
    border-radius: 4px;

    .header {
      display: flex;
      align-items: center;
      padding: 8px 8px 8px 16px;

      .label {
        @extend .text-sm;
        color: var(--icon-color);
        flex: 1;
      }
    }

    .filter {
      display: flex;
      padding: 0px 8px 16px 16px;

      :deep #field div {
        width: 137px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 0px;
      }
    }

    .remove-filter {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add-filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      color: var(--secondary);
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
